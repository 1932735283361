/*--ONLINE--*/
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Sacramento&family=Oswald&display=swap');
/*--END ONLINE--*/

/*--BASED--*/
.classfontbrand {
	font-family: 'Black Ops One', cursive;
	font-size:30px;
	font-weight: bolder;
	color: #144754;
	/*margin-bottom: -9px;/*-*/
}
.classfontbrandsub {
	font-family: 'Sacramento', cursive;
	font-size:25px;
	margin: -9px 0;
	padding: 0;
}
.classfontoswald {
	font-family: 'Oswald', sans-serif;
	color: navy;
}
.classfontcolormain { color: #144754; }
.classfontcolorsecond { color: #35bee0; }
.classheadertop {
	/*background: white url(../img/bg_header_top.png) top left repeat-x;/**/
	/*background-image: linear-gradient(#35bee0,#DCDCDC);/**/
	background-color:white;
	background-image: linear-gradient(rgba(53,190,224,0.5),#DCDCDC);/**/
}
.classbglogintop {
	position: fixed;
	bottom:0;
	left:0;
	width:100%;
	height:100%;
	background-color: transparent;
	background-position: bottom center;
	background-repeat: no-repeat;
	opacity: 0.1;
	transform: rotate(180deg);
	/*border:1px solid red;/**/
}
.classcursorpointer {
	cursor: pointer;
	color: inherit;
}
.classlinkinherit {
	color: inherit;
	text-decoration: none !important;
}
.classfakelink {
	cursor: pointer;
	border: 1px solid #FC3A1F55;
	padding: 3px;
	border-radius: 3px;
	vertical-align:middle;
}
.classpetunjuk {
	color: #FD5544;
	font-style:italic;
	font-size:80%;
	padding-left:15px;
}
.classbgwhite {
	background-color: white;
}
.classbgcard {
	background-color: #FFFFFF99 !important;
}
.classfontsmaller {
	font-size: smaller !important;
}
.classfontlarger {
	font-size: larger !important;
}
.classToTop {
	cursor:pointer;
	position:fixed;
	min-width: 30px;
	z-index:1030;
	bottom: 35px;
	right:20px;
	opacity:1;
	transition: width 0.1s, height 0.1s, opacity 0.1s 0.1s;
}
.classtooltipcheck {
	position:fixed;
	top:0;
	left:0;
	z-index:1091 !important;
	display:none;/**/
}
.classrowselect {
	background-color: rgba(#FEE1B6, 0.3) !important;
}
.classposmidcenter {
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1060;
}
.classtombol {
	font-family: 'Oswald', sans-serif;
	font-size: larger;
	color: #F9E79F !important;
	border-radius: 10px !important;
	border-style:none !important;
	background-image: linear-gradient(#DCDCDC,#144754);
}
.classbgdisabled {
	background-color: #f0f0f0 !important;
}
.classtombol:disabled {
	color: rgba(black,0.6) !important;
	background-image: linear-gradient(#f0f0f0,gray);
}
.classbordertmp {
	border: 1px solid red !important;/**/
} 
.classborderbottom {
	border-bottom: 1px dotted #DCDCDC !important;
}
.classborderdashed {
	border-bottom-style: dashed !important;
}
.classboxthumbs {
	display: inline-flex;
	justify-content:center;
	align-items: center;
	height:70px;width:70px;
	border: 1px dashed #DCDCDC;
	border-radius: 3px;
	vertical-align:middle;
}
.classactionthumbs:hover {
    background: transparent url(../img/icon_close.png) center center no-repeat;
    background-size: 25px 25px;
	background-color: rgba(gray,0.85) !important;
}
.classeditthumbs:hover {
    background: transparent url(../img/icon_edit.png) center center no-repeat;
    background-size: 25px 25px;
	background-color: rgba(gray,0.85) !important;
}
/*--END BASED--*/

/*--IKON--*/
.classverticalmiddle {
    vertical-align: middle;
}
.classikonbox {
	border:1px solid #FBD198;
	border-radius: 5px;
	background-color: rgba(#FFC373,0.3);
	padding: 2px;
	margin: auto 5px;
	vertical-align:middle;
	color: #CF8015;
}
.classikontabel {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    margin-right: 3px;
}
.classikon {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    margin-right: 3px;
}
.classikonavatar {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    background-color: #DCDCDC !important;
	border: 1px dotted white;
}
.classikontambah {
	background: transparent url(../img/icon_tambah.png) center center no-repeat;
	background-size: cover;
}
.classikonedit {
	background: transparent url(../img/icon_edit.png) center center no-repeat;
	background-size: cover;
}
.classikonhapus {
	background: transparent url(../img/icon_hapus.png) center center no-repeat;
	background-size: cover;
}
.classikoncetak {
	background: transparent url(../img/icon_cetak.png) center center no-repeat;
	background-size: cover;
}
.classikonexcel {
	background: transparent url(../img/icon_excel.png) center center no-repeat;
	background-size: cover;
}
.classikonreset {
	background: transparent url(../img/icon_reset.png) center center no-repeat;
	background-size: cover;
}
/*
.classikonlogowhite {
	background: transparent url(../img/icon_logowhite.png) center center no-repeat;
	background-size: cover;
}
.classikonlogo {
	background: transparent url(../img/icon_logo.png) center center no-repeat;
	background-size: cover;
}
.classikondown {
	background: transparent url(../img/icon_down.png) center center no-repeat;
	background-size: cover;
}
.classikondashboard {
	background: transparent url(../img/icon_dashboard.png) center center no-repeat;
	background-size: cover;
}/**/
/*--IKON--*/


/*--TAMBAHAN--*/
.classblinking {
	-webkit-animation: blink 1s step-end infinite;
	-moz-animation: blink 1s step-end infinite;
	-o-animation: blink 1s step-end infinite;
	animation: blink 1s step-end infinite;
}
@-webkit-keyframes blink {
	67% { opacity: 0 }
}
@-moz-keyframes blink {
	67% { opacity: 0 }
}
@-o-keyframes blink {
	67% { opacity: 0 }
}
@keyframes blink {
	67% { opacity: 0 }
}

a:hover.classnounderline {
	text-decoration: none !important;
}
/*--END TAMBAHAN--*/