/* src/Odontogram.css */

.classodontogram,
	.classmodontogram {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	min-width: 750px;/*--*/

	/*display: grid;
	grid-template-columns: repeat(auto-fill, minmax(48px, 1fr));
	gap: 1px;
	justify-content: center; /**/
}
.classmodontogram {
	min-width: 700px;
}

.classodontogram .classtooth-svg {
	display: inline-block;
	margin: 3px 1px;/*--*/
}
.classmodontogram .classtooth-svg {
	margin: 3px 0.5px;
}
	
.classsurface {
	fill: transparent;
	stroke: black;
	stroke-width: 1;
	cursor: pointer;
	transition: fill 0.5s ease; /* Smooth transition for color change */
	pointer-events: auto;
}
.classsurfacem {
	fill: transparent;
	stroke: black;
	stroke-width: 1;
	pointer-events: auto;
}
.classall {
	fill: none;
	stroke: black;
	stroke-width: 0;
	pointer-events: none;
}
.classklikall {
	stroke: none; stroke-width:none;
	fill: transparent;
}
.classklikall:active {
	/*fill: red !important;/**/
	fill: #FFFF00 !important;/**/
}

.classsurfacem.classamf,
	.classsurface.classamf {
	fill: #80808099;
}
.classsurfacem.classcar,
	.classsurface.classcar, 
	.classall.classcar {
	stroke-width: 5;
}
.classsurfacem.classcar,
	.classsurface.classcar {
	fill: #00800099;
}
.classsurfacem.classfis,
	.classsurface.classfis {
	fill: #FFC0CB99;
}
.classsurfacem.classgif,
	.classsurface.classgif {
	fill: #00800099;
}

.classall.classamf {
	fill: #80808099;
	stroke-width: 3;
}
.classall.classcof {
	fill: #00800099;
	stroke-width: 3;
}
.classall.classfis {
	fill: #FFC0CB99;
	stroke-width: 3;
}
.classall.classfmc {
	fill: black;
	stroke-width: 3;
}
.classall.classinl {
	fill: black;
	stroke-width: 5;
}
.classall.classonl {
	fill: black;
	stroke-width: 5;
}
.classall.classpoc {
	fill: #00800099;
	stroke-width: 5;
}
.classall.classmpc {
	fill: #00800099;
	stroke-width: 5;
}
.classall.classgmc {
	fill: red;
	stroke-width: 5;
}
.classall.classacr {
	fill: #FFC0CB99;
	stroke-width: 5;
}

.classsurface:hover {
	fill: #FFFF0099 !important;
}

.classtoothsample {
	fill: none;
	stroke: black;
	stroke-width: 1;
}
.classtoothsample.classamf {
	fill: #80808099;
}
.classtoothsample.classcar, 
	.classall.classcar {
	stroke-width: 5;
}
.classtoothsample.classcof {
	fill: #00800099;
}
.classtoothsample.classfis {
	fill: #FFC0CB99;
}
.classtoothsample.classgif {
	fill: #00800099;
}
.classtoothsample.classfmc {
	fill: black;
	stroke-width: 5;
}
.classtoothsample.classinl {
	fill: black;
	stroke-width: 5;
}
.classtoothsample.classonl {
	fill: black;
	stroke-width: 5;
}
.classtoothsample.classpoc {
	fill: #00800099;
	stroke-width: 5;
}
.classtoothsample.classmpc {
	fill: #00800099;
	stroke-width: 5;
}
.classtoothsample.classgmc {
	fill: red;
	stroke-width: 5;
}
.classtoothsample.classacr {
	fill: #FFC0CB99;
	stroke-width: 5;
}